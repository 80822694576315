
















































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsCreateSender from '@/components/VsCreateSender/index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsConfirmSender from '@/components/VsConfirmSender/index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsSenderEmailCard from '@/modules/senders/components/VsSenderEmailCard/Index.vue'
import VsDkimModal from '@/modules/senders/components/VsDkimModal/Index.vue'
import { getSendersEmail, verifySenderSpf, deleteSenderAuthorized } from '@/api/consoleApi/senders/emails'
import { UserModule } from '@/store/modules/user'
import { AppModule } from '@/store/modules/app'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'

@Component({
    name: 'SendersEmail',
    components: {
        VsCreateSender,
        VsLoader,
        VsConfirmSender,
        VsSenderEmailCard,
        VsConfirm,
        VsDkimModal,
        VsDropdownButton,
        VsSectionHeader,
    },
})
export default class extends Vue {
    private loading = false
    senders = []
    totalItems = 0
    spfModal = false

    private filters = {
        status: '',
        search: '',
    }

    private pagination: any = {
        currentPage: 1,
        itemsPerPage: 10,
        orderBy: null,
    }

    $refs: any

    get user () {
        return UserModule.user
    }

    get universityUrl () {
        return AppModule.universityUrl
    }

    get is4Dem () {
        return AppModule.consoleConf?.is4Dem
    }

    get senderStatus () {
        return [
            {
                label: 'Confermato',
                value: 'enabled',
            },
            {
                label: 'Da confermare',
                value: 'disabled',
            },
        ]
    }

    get domain () {
        return window.location.host
    }

    private openCreateSenderModal (sender?: any) {
        this.$refs.createSender.openModal(sender)
    }

    async deleteSender (sender: any) {
        try {
            await this.$refs.vsConfirmDeleteSenderEmail.openConfirm()
        } catch (e) {
            return
        }
        try {
            await deleteSenderAuthorized(sender.id)
            this.$root.$vsToast({
                heading: 'Mittente cancellato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.getSenders()
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore durante la cancellazione del mittente',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    private async verifySenderSpf (sender: any) {
        try {
            await verifySenderSpf(sender.id)
            this.$root.$vsToast({
                heading: 'Verifica SPF andata a buon fine',
                timeout: 5000,
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Verifica SPF non andata a buon fine',
                message: 'Per informazioni su come configurare corretamente il record SPF clicca sul bottone "Configura SPF"',
                timeout: 5000,
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    @Watch('filters', { deep: true, immediate: false })
    async clearPaginationAndGetData () {
        this.pagination.currentPage = 1
        this.getSenders()
    }

    @Watch('pagination', { immediate: true, deep: true })
    private async getSenders () {
        try {
            this.loading = true
            const resp = await getSendersEmail(this.buildParams())
            this.senders = resp.data.data
            this.totalItems = resp.data.meta.pagination.total
        } catch (e) {
            console.log(e)
            this.senders = []
            this.totalItems = 0
        }
        this.loading = false
    }

    private buildParams () {
        const params: any = {
            orderBy: 'id',
            sortedBy: 'desc',
            page: this.pagination.currentPage,
            limit: this.pagination.itemsPerPage,
            searchJoin: 'and',
            search: this.buildSearch(),
        }

        return params
    }

    private buildSearch () {
        const search = [
            this.filters.status === 'notConfirmed' ? 'confirmed:0' : '',
            this.filters.status === 'enabled' ? 'confirmed:1;status:Enabled' : '',
            this.filters.status === 'disabled' ? 'confirmed:1;status:Disabled' : '',
            this.filters.status === 'approval' ? 'confirmed:1;status:Approval' : '',
            this.filters.search.trim() !== '' ? `alias:${this.filters.search}` : '',
        ].filter((el) => el !== '')

        return search.join(';')
    }
}
